

.player {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    margin: 0.5vh;
    justify-content: start;
    align-items: center;
    transition: 1s height, 1s transform;
    transform: scale(1);
    height: 20%;

    .potato {
        position: relative;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 15vh;
        width: auto;
        z-index: 1;
    }

    .name {
        font-family: "Interstate";
        color: black;
        font-weight: bold;
        background-color: white;
        border-radius: 0.5vh;
        font-size: 2.25vh;
        border: solid black 0.5vh;
        box-shadow: 0.75vh 0.75vh 0px #000000;
        text-align: center;
        padding: 0.5vh;
        width: fit-content;
        min-width: 15vh;
        transform: translate(-4.5vh, 2vh);
        height: 5vh;
        justify-content: center;
        align-items: center;
        display: flex;

        &.large {
            font-size: 2.75vh;
        }

        &.medium {
            font-size: 2.25vh;
        }

        &.small {
            font-size: 1.75vh;
        }
    }

    &.reverse {
        flex-direction: row-reverse;
        justify-content: end;

        .name {
            transform: translate(4.5vh, 2vh);
        }
    }
}
