div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.tutorialContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    z-index: 0;

    .voteCount {
        position: absolute;
        margin: auto;
        top: 2vh;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 4vh;
        font-family: "Interstate";
        paint-order: stroke fill;
        -webkit-text-stroke: 1vh black;
        font-weight: bold;
        color: white;
        text-shadow: 0.5vh 0.5vh 0px #000000, 0.25vh 0.25vh 0px #000000;
        /*text-shadow: 0.25vh 0.25vh 0.25vh black;*/
    }

    .genericTitle {
        font-size: 10vh;
        font-family: "Logkey";
        color: white;
        -webkit-text-stroke: 1vh black;
        text-shadow: 0.25vh 0.25vh 0 #000000, 0.5vh 0.5vh 0 #000, 0.75vh 0.75vh 0 #000000, 1.25vh 1.25vh 0 #000;
        paint-order: stroke fill;
        text-align: center;
        line-height: 0.65;
        text-transform: uppercase;
        transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
        transform: scale(0);

        &.bigger {
            font-size: 30vh;
        }

        &.medium {
            font-size: 20vh;
        }

        &.show {
            transform: scale(1);
            transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
        }
    }

    .subText {
        color: black;
        text-align: center;
        font-size: 4vh;
        font-family: "Interstate";
        font-weight: bold;
        transform: scale(0);
        transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
        text-wrap: nowrap;

        &.show {
            transform: scale(1);
            transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
        }

        &.smaller {
            font-size: 3vh;
        }
    }


    .votingPlayerSection {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10vh;
        width: fit-content;
        height: fit-content;
        bottom: 7.5vh;
        transition: transform linear 0.4s;
        transform: scale(0);
        transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
        transform: scale(0);

        &.show {
            transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
            transform: scale(1);
        }

        &.hide {
            transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
            transform: scale(0);
        }

        .votingPlayer {
            display: flex;
            width: fit-content;
            height: fit-content;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            transform: scale(1.35);

            &.left {
                .name {
                    transform: translate(-3.5vh, 2vh);
                    padding-left: 1.5vh;

                    &.highlight {
                        transform: translate(-3.5vh, -1vh);
                    }
                }
            }

            &.right {
                .name {
                    transform: translate(3.5vh, 2vh);
                    padding-right: 1.5vh;

                    &.highlight {
                        transform: translate(3.5vh, -1vh);
                    }
                }
            }

            .potato {
                position: relative;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                height: 14vh;
                width: auto;
                z-index: 1;
            }

            .name {
                font-family: "Interstate";
                color: black;
                font-weight: bold;
                background-color: white;
                border-radius: 0.35vh;
                font-size: 2vh;
                border: solid black 0.35vh;
                box-shadow: 0.5vh 0.5vh 0px #000000;
                text-align: center;
                padding: 0.35vh;
                width: fit-content;
                z-index: 1;
                height: fit-content;
                z-index: 0;
                transition: 0.5s ease-in-out transform;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 1vh;
                padding-right: 1vh;

                &.highlight {
                    background-color: #f2e626;
                }
            }
        }
    }



    .step {
        display: flex;
        flex-direction: column;
        margin: auto;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        /*opacity: 0;*/
        /*transition: opacity 0.5s cubic-bezier(0.45, 0, 0.55, 1);*/
        justify-content: center;
        align-items: center;

        @for $i from 1 through 10 {
            .animate:nth-child(#{$i}n) {
                transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
                transition-delay: #{$i * 0.25}s;
                transform: scale(0);
            }
        }

        &.show {
            /*opacity: 1;*/

            @for $i from 1 through 10 {
                .animate:nth-child(#{$i}n) {
                    transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
                    transition-delay: #{$i * 0.25}s;
                    transform: scale(1);
                }
            }
        }
    }


    .scoreColumn {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
        gap: 0.5vh;
        /*justify-content: start;*/
        align-items: end;
        position: absolute;
        margin: auto;
        bottom: 5vh;
        right: 8vh;
        transform: scale(0);
        transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;

        &.show {
            transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
            transform: scale(1);
        }

        .flag {
            width: 12vh;
            height: auto;
        }

        .scoreBox {
            width: 12vh;
            height: 2.75vh;
            position: relative;
            animation: 0.25s boxpulse linear;


            .pointGained {
                position: absolute;
                height: 6vh;
                width: 6vh;
                text-align: center;
                font-size: 6vh;
                font-family: "Logkey";
                line-height: 0.65;
                font-weight: bold;
                opacity: 0;
                background-color: white;
                border-radius: 50%;
                animation: fade 2s linear;
                top: 0;
                bottom: 0;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 0.5vh;

                &.teamA {
                    color: #f171a0;
                    left: -7vh;
                }

                &.teamB {
                    color: black;
                    right: -7vh;
                }

                @keyframes fade {
                    0% {
                        opacity: 0;
                    }

                    30% {
                        opacity: 1;
                    }

                    60% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                    }
                }
            }

            .innerBox {
                background-color: white;
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0.5;
                display: flex;
                align-items: center;
                justify-content: center;

                &.highlight {
                    opacity: 1;

                    .scoreNum {
                        opacity: 1;
                    }
                }

                &.ruleZone {
                    &.highlight {
                        background-color: #ffe74b;
                    }
                }

                .scoreNum {
                    text-align: center;
                    font-size: 2.5vh;
                    line-height: 1;
                    font-family: "Interstate";
                    font-weight: bold;
                    opacity: 0;

                    &.black {
                        color: black;
                    }

                    &.pink {
                        color: #f171a0;
                    }
                }
            }


            &.dangerZone {
                border: solid 0.5vh white;

                .innerBox {
                    &.highlight {
                        --spacing: 20%;
                        --color-1: #fff;
                        --color-2: lightgrey;

                        &.pink {
                            --color-2: #ffd0e1;
                        }

                        background: repeating-linear-gradient(60deg, var(--color-1) 0%, var(--color-1) var(--spacing), var(--color-2) calc(var(--spacing) + 2px), var(--color-2) calc(var(--spacing)*2));
                        animation: animatedGradient 2s linear infinite;
                        background-size: 50% 100%;

                        @keyframes animatedGradient {
                            0% {
                                background-position: 0 0;
                            }

                            100% {
                                background-position: 100% 0;
                            }
                        }
                    }
                }

                &.pulse {
                    .dangerZoneMark {
                        animation: boxpulse 2s infinite ease-in-out;
                    }
                }
            }

            @keyframes boxpulse {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(1.2);
                }

                100% {
                    transform: scale(1);
                }
            }

            .newRuleMark {
            }

            .dangerZoneMark {
            }

            .newRuleMark {
                position: absolute;
                margin: auto;
                top: 0;
                bottom: 0;
                width: 100%;
                height: fit-content;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-direction: row-reverse;
                right: 90%;

                .arrow {
                    width: 0;
                    height: 0;
                    border-top: 1vh solid transparent;
                    border-bottom: 1vh solid transparent;
                    border-left: 2vh solid white;
                    border-right: unset;
                }

                .ruleText {
                    font-family: "Logkey";
                    color: white;
                    font-size: 4vh;
                    text-align: center;
                    width: 75%;
                    line-height: 0.65;
                    margin-top: 0.75vh;
                }
            }

            .dangerZoneMark {
                position: absolute;
                margin: auto;
                top: 0;
                bottom: 0;
                width: 100%;
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                right: 120%;

                .warning {
                    width: 5vh;
                    height: 5vh;
                }

                .zoneText {
                    font-family: "Logkey";
                    color: white;
                    font-size: 5vh;
                    text-align: center;
                    width: 100%;
                    line-height: 0.65;
                    margin-top: 0.75vh;
                }
            }
        }
    }


    .stepOne {
    }


    .chooseTeamSection {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
        transform: scale(0);

        &.show {
            transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
            transform: scale(1);
        }

        .teamTitleGroup {
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 1s ease-in-out;
            width: fit-content;
            height: fit-content;
            transform: scale(1);
            transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;

            &.show {
                transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
                transform: scale(1);
            }
        }

        .teamSection {
            position: absolute;
            width: fit-content;
            height: fit-content;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            gap: 1vh;
            margin: auto;
            justify-content: start;
            align-items: center;
            height: 30vh;
            transform: scale(1);


            .title {
                color: white;
                font-family: "Logkey";
                font-size: 10vh;
                text-align: center;
                line-height: 0.65;
                width: fit-content;
                padding-left: 2vh;
                padding-right: 2vh;
                padding-top: 0.75vh;
                padding-bottom: 0.25vh;
                border-radius: 0.5vh;
            }

            .teamBox {
                display: flex;
                width: 40vh;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                gap: 5%;

                .teamMember {
                    font-family: "Interstate";
                    color: black;
                    font-weight: bold;
                    background-color: white;
                    border-radius: 0.5vh;
                    font-size: 2vh;
                    border: solid black 0.5vh;
                    box-shadow: 0.75vh 0.75vh 0px #000000;
                    text-align: center;
                    padding: 0.5vh;
                    width: 45%;
                    margin-top: 2vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 5vh;
                    animation: popup 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);

                    &.small {
                        font-size: 1.75vh;
                    }

                    @keyframes popup {
                        0% {
                            transform: scale(0);
                        }


                        100% {
                            transform: scale(1);
                        }
                    }
                }
            }

            &.teamA {
                left: 12.5vh;

                .title {
                    background-color: #f96fa0;
                    border: solid 0.25vh #E35C8D;
                }
            }

            &.teamB {
                right: 12.5vh;

                .title {
                    background-color: black;
                }
            }
        }
    }
}
