@function black($opacity) {
    @return rgba(black, $opacity);
}

@function white($opacity) {
    @return rgba(white, $opacity);
}

.menuContainer {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background-color: black(0.5);
    z-index: 1002;
    touch-action: initial;
    pointer-events: initial;

    &.open {
        display: flex;
    }

    .menuSection {
        position: absolute;
        width: 40vh;
        height: fit-content;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: #1A1C2E;
        border-radius: 2vh;
        border: 1vh solid #FF5F6B;
        display: flex;
        flex-direction: column;
        transform: scale(0);
        transition: transform 1s ease;

        &.open {
            transform: scale(1);
        }

        .toggle {
            margin: auto;
            font-size: 3vh;
            text-align: center;
            color: white;
            /*font-family: "Resistenza";*/
            width: 100%;
        }

        .spacer {
            margin: auto;
            margin-top: 0.5vh;
            margin-bottom: 0.5vh;
            /*margin-bottom: 1vh;*/
            height: 0.25vh;
            width: 90%;
            background-color: white(0.3);
        }

        .button {
            margin: auto;
            font-size: 3vh;
            text-align: center;
            color: white;
            /*font-family: "Resistenza";*/
            width: 100%;
            margin-top: 1vh;
            cursor: pointer;
        }
    }
}

