[contenteditable] {
    outline: 0px solid transparent;
}

:global(div) {
    touch-action: manipulation;
}

:global(img) {
    touch-action: manipulation;
}

div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.teamBG {
    position: fixed;
    width: 100%;
    height: 120%;
}

.clientContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 700px !important;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: #FA6C9F;
    padding-bottom: 20px;
    overflow: scroll;

    &.teamA {
    }

    &.teamB {
        .subText {
            color: white;
        }

        .voteSection {
            .voteButton {
                color: black;
            }
        }
    }


    .pauseContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        z-index: 10000;

        .pauseText {
            margin: auto;
            width: fit-content;
            height: fit-content;
            font-size: 8vh;
            color: white;
        }
    }


    .connectionIssueContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        flex-direction: column;
        z-index: 10000;
        justify-content: center;
        align-items: center;

        .connectionText {
            width: fit-content;
            height: fit-content;
            font-size: 30px;
            color: white;
            font-family: "Interstate";
            text-align: center;
        }

        .refreshButton {
            font-family: 'Logkey';
            padding: 10px;
            line-height: 1;
            font-weight: bolder;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;
            width: fit-content;
            height: fit-content;
            font-size: 75px;
            color: white;
            border-radius: 20px;
            cursor: pointer;
            background-color: #FFE849;
            border: black solid 3px;
            box-shadow: 5px 5px 0px #000000;
        }
    }


    .skipContainer {
        background-color: black;
        border-radius: 1vh;
        width: fit-content;
        height: fit-content;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;

        .skipButton {
            font-size: 4vh;
            color: white;
            text-align: center;
            padding: 1vh;
            margin: auto;
            position: relative;
        }
    }

    .dangerZoneNotification {
        position: relative;
        width: 75%;
        background-color: white;
        border-radius: 15px;
        padding: 5px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        z-index: 2;

        .text {
            font-family: "Interstate";
            font-weight: bold;
            font-size: 15px;
            text-align: center;
            color: black;
        }
    }

    .subText {
        color: black;
        text-align: center;
        font-size: 20px;
        font-family: "Interstate";
        font-weight: bold;
        line-height: 1;
    }

    .selectTeamSection {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        flex-wrap: nowrap;
        gap: 20px;
        z-index: 1;
        padding-top: 10px;
        justify-content: center;
        align-items: center;


        .playerSection {
            height: fit-content;
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: column;

            .potato {
                height: 150px;
                margin-left: auto;
                margin-right: auto;
                cursor: pointer;
                user-select: all;
            }

            .text {
                font-size: 65px;
                font-family: "Logkey";
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                color: white;
                -webkit-text-stroke: 7.5px black;
                text-shadow: 5px 5px 0 #000, 7.5px 7.5px 0 #000;
                paint-order: stroke fill;
                line-height: 0.65;
            }
        }


        .teamList {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: fit-content;
            flex-wrap: nowrap;
            gap: 20px;
            justify-content: center;
            align-items: center;

            .teamOption {
                background-color: white;
                border-radius: 20px;
                border: solid black 5px;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                padding: 25px;
                gap: 5px;
                justify-content: center;
                align-items: center;
                width: 90%;

                &.selected {
                    background-color: #ffe84a;
                }

                .title {
                    font-family: "Logkey";
                    color: black;
                    font-size: 50px;
                    line-height: 0.65;
                    text-align: center;
                    text-transform: uppercase;
                }

                .description {
                    font-family: "Interstate";
                    font-weight: bold;
                    line-height: 1;
                    color: black;
                    font-size: 20px;
                    text-align: center;
                }
            }
        }
    }

    .dingerSection {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        flex-wrap: nowrap;
        gap: 15px;
        justify-content: start;
        align-items: center;
        z-index: 1;
        justify-content: center;
        align-items: center;

        .textSection {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: fit-content;
            height: fit-content;

            .title {
                font-size: 80px;
                font-family: "Logkey";
                color: white;
                -webkit-text-stroke: 1vh black;
                text-shadow: 5px 5px 0 #000, 7.5px 7.5px 0 #000;
                paint-order: stroke fill;
                text-align: center;
                line-height: 0.65;
                margin-top: 5px;
                margin-bottom: 2.5px;
            }

            .largeLetter {
                margin-top: 40px;
                height: 300px;
            }
        }

        .bellSection {
            width: 220px;
            height: 220px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;

            img {
                position: absolute;
                margin: auto;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
            }

            .base {
                width: 100%;
                height: 100%;
            }

            .detail {
                width: 100%;
                height: 100%;
            }

            .top {
                width: 30%;
                height: 30%;
                animation: press cubic-bezier(0.34, 1.56, 0.64, 1) 0.35s;

                @keyframes press {
                    0% {
                        transform: scale(1)
                    }

                    50% {
                        transform: scale(0.75);
                    }

                    100% {
                        transform: scale(1);
                    }
                }
            }

            .arc {
                position: absolute;
                height: 95%;
                width: 100%;
                border-radius: 50%;
                top: 0;
                bottom: 0;
                margin: auto;
                z-index: -1;

                &.right {
                    border-right: 2px solid black;
                    animation: moveRight 0.35s linear;
                    right: 20px;

                    &.smaller {
                        animation: moveRightSmaller 0.35s linear;

                        @keyframes moveRightSmaller {
                            0% {
                                transform: translateX(0);
                                opacity: 1;
                            }

                            50% {
                                opacity: 1;
                            }

                            100% {
                                transform: translateX(75px);
                                opacity: 0;
                            }
                        }
                    }

                    @keyframes moveRight {
                        0% {
                            transform: translateX(0);
                            opacity: 1;
                        }

                        50% {
                            opacity: 1;
                        }

                        100% {
                            transform: translateX(50px);
                            opacity: 0;
                        }
                    }
                }

                &.left {
                    border-left: 2px solid black;
                    animation: moveLeft 0.35s linear;
                    left: 20px;

                    &.smaller {
                        animation: moveLeftSmaller 0.35s linear;

                        @keyframes moveLeftSmaller {
                            0% {
                                transform: translateX(0);
                                opacity: 1;
                            }

                            50% {
                                opacity: 1;
                            }

                            100% {
                                transform: translateX(-75px);
                                opacity: 0;
                            }
                        }
                    }

                    @keyframes moveLeft {
                        0% {
                            transform: translateX(0);
                            opacity: 1;
                        }

                        50% {
                            opacity: 1;
                        }

                        100% {
                            transform: translateX(-50px);
                            opacity: 0;
                        }
                    }
                }

                &.smaller {
                    height: 75%;
                    width: 75%;
                }
            }
        }



        .cardsSection {
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .card {
                position: relative;
                width: 225px;
                height: 112.5px;
                background-color: white;
                font-family: "Interstate";
                font-weight: bold;
                font-size: 27.5px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: solid black 3px;
                border-radius: 10px;
                text-align: center;
                flex-wrap: wrap;
                line-height: 1;
                box-shadow: 5px 5px 20px rgba(0,0,0,0.5);
                color: black;

                .letter {
                    position: absolute;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: 5%;
                    font-family: "Interstate";
                    font-weight: bold;
                    font-size: 15px;
                    color: black;
                }

                &:nth-child(even) {
                    transform: rotate(10deg);
                    animation: popup 0.75s reverse;
                }

                &:nth-child(odd) {
                    transform: rotate(-10deg) translate(-15px, 15px);
                    animation: popupOdd 0.75s reverse;
                    z-index: 1;
                }

                @keyframes popup {
                    0% {
                        transform: scale(1) rotate(10deg);
                    }

                    4% {
                        transform: scale(0.98) rotate(10deg);
                    }

                    8% {
                        transform: scale(0.99) rotate(10deg);
                    }

                    18% {
                        transform: scale(0.94) rotate(10deg);
                    }

                    26% {
                        transform: scale(0.98) rotate(10deg);
                    }

                    46% {
                        transform: scale(0.75) rotate(10deg);
                    }

                    64% {
                        transform: scale(0.98) rotate(10deg);
                    }

                    76% {
                        transform: scale(0.44) rotate(10deg);
                    }

                    88% {
                        transform: scale(0.11) rotate(10deg);
                    }

                    100% {
                        transform: scale(0) rotate(10deg);
                    }
                }

                @keyframes popupOdd {
                    0% {
                        transform: scale(1) rotate(-10deg) translate(-15px, 15px);
                    }

                    4% {
                        transform: scale(0.98) rotate(-10deg) translate(-15px, 15px);
                    }

                    8% {
                        transform: scale(0.99) rotate(-10deg) translate(-15px, 15px);
                    }

                    18% {
                        transform: scale(0.94) rotate(-10deg) translate(-15px, 15px);
                    }

                    26% {
                        transform: scale(0.98) rotate(-10deg) translate(-15px, 15px);
                    }

                    46% {
                        transform: scale(0.75) rotate(-10deg) translate(-15px, 15px);
                    }

                    64% {
                        transform: scale(0.98) rotate(-10deg) translate(-15px, 15px);
                    }

                    76% {
                        transform: scale(0.44) rotate(-10deg) translate(-15px, 15px);
                    }

                    88% {
                        transform: scale(0.11) rotate(-10deg) translate(-15px, 15px);
                    }

                    100% {
                        transform: scale(0) rotate(-10deg) translate(-15px, 15px);
                    }
                }
            }
        }
    }

    .ruleList {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 5px;
        justify-content: center;
        align-items: center;

        .rule {
            width: 90%;
            height: fit-content;
            background-color: white;
            padding: 10px;
            font-family: "Interstate";
            font-weight: bold;
            font-size: 18px;
            border: solid black 3px;
            border-radius: 10px;
            text-align: center;
            flex-wrap: wrap;
            line-height: 1;
        }
    }

    .voteSection {
        display: flex;
        flex-direction: row;
        width: 100%;
        /*height: 100%;*/
        justify-content: center;
        align-items: center;
        gap: 15px;

        .voteButton {
            height: 60px;
            width: 60px;
            text-align: center;
            font-size: 45px;
            font-family: "Interstate";
            font-weight: bold;
            background-color: white;
            border-radius: 50%;
            animation: fade 2s linear;
            color: #f171a0;
            justify-content: center;
            align-items: center;
            display: flex;

            &:disabled {
                background-color: #D3D3D3; /* Light grey background to show disabled state */
                color: #A9A9A9; /* Grey text color to match the disabled tone */
                cursor: not-allowed; /* Change cursor to indicate it is not clickable */
                transform: none; /* Disable transform effects */
                /*opacity: 0.6;*/ /* Add slight transparency for visual cue */
            }
        }

        .voteNumber {
            font-size: 100px;
            font-family: "Interstate";
            color: white;
            -webkit-text-stroke: 5px black;
            text-shadow: 5px 5px 0 #000, 7.5px 7.5px 0 #000;
            paint-order: stroke fill;
            text-align: center;
            margin-top: 5px;
            margin-bottom: 2.5px;
        }
    }

    .buttons {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;


        .mainButton {
            font-size: 25px;
            font-weight: bold;
            font-family: "Interstate";
            padding: 5px;
            line-height: 1;
            background-size: contain;
            background-repeat: no-repeat;
            text-align: center;
            background-position: center;
            background-color: #FFE849;
            border-radius: 10px;
            color: black;
            width: 250px;
            border: black solid 3px;
            box-shadow: 5px 5px 0px #000000;
            transition: 0.25s cubic-bezier(0.34, 1.56, 0.64, 1) transform, 0.25s cubic-bezier(0.34, 1.56, 0.64, 1) box-shadow;

            &:hover {
                /* Scale down slightly when pressed */
                transform: translate(2.5px, 2.5px);
                box-shadow: 2.5px 2.5px 0px #000000;
            }

            &:active {
                /* Scale down slightly when pressed */
                transform: translate(5px, 5px);
                box-shadow: 0px 0px 0px #000000;
            }

            &:disabled {
                background-color: #D3D3D3; /* Light grey background to show disabled state */
                color: #A9A9A9; /* Grey text color to match the disabled tone */
                border: #A9A9A9 solid 3px; /* Grey border to soften the button */
                box-shadow: none; /* Remove shadow to make it look inactive */
                cursor: not-allowed; /* Change cursor to indicate it is not clickable */
                transform: none; /* Disable transform effects */
                /*opacity: 0.6;*/ /* Add slight transparency for visual cue */
            }

            &.alt {
                background-color: white;
            }
        }
    }

    .timer {
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        position: relative;

        .watchButton {
            width: 20px;
            position: absolute;
            top: -10px;
        }

        .faceSection {
            width: 100px;
            height: 100px;
            position: relative;

            .watchFace {
                width: 100%;
                height: auto;
                position: absolute;
            }

            .faceText {
                font-family: 'Logkey';
                font-size: 65px;
                color: black;
                text-align: center;
                z-index: 1;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 7.5px;
                bottom: 0;
                line-height: 0.65;
                height: fit-content;
            }
        }
    }
}
