.defaultSection {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .playerSection {
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;

        .potato {
            height: 175px;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            user-select: all;
        }

        .text {
            /*font-size: 100px;*/
            font-family: "Logkey";
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            color: white;
            -webkit-text-stroke: 7.5px black;
            text-shadow: 5px 5px 0 #000, 7.5px 7.5px 0 #000;
            paint-order: stroke fill;
            line-height: 0.65;
            z-index: 1;

            &.large {
                font-size: 100px;
            }

            &.medium {
                font-size: 80px;
            }

            &.small {
                font-size: 60px;
            }

            &.tiny {
                font-size: 40px;
            }
        }
    }

    .ruleList {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 5px;
        justify-content: center;
        align-items: center;
        margin-top: 5%;

        .rule {
            width: 90%;
            height: fit-content;
            background-color: white;
            padding: 10px;
            font-family: "Interstate";
            font-weight: bold;
            font-size: 18px;
            border: solid black 3px;
            border-radius: 10px;
            text-align: center;
            flex-wrap: wrap;
            line-height: 1;
        }
    }


    .teamInfo {
        position: relative;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin-top: 5%;
        width: 100%;



        .largeLetter {
            font-size: 300px;
            font-family: "Logkey";
            color: white;
            -webkit-text-stroke: 1vh black;
            text-shadow: 2px 2px 0 #000000, 5px 5px 0 #000, 10px 10px 0 #000000, 15px 15px 0 #000;
            paint-order: stroke fill;
            text-align: center;
            line-height: 0.65;
        }
    }

    &.teamB {
        .subText {
            color: white;
        }
    }

    .subText {
        color: black;
        text-align: center;
        font-size: 20px;
        font-family: "Interstate";
        font-weight: bold;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .skipBox {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: fit-content;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;

        input[type="checkbox"] {
            -webkit-appearance: none;
            appearance: none;
            background-color: white;
            margin: 0;
            font: inherit;
            width: 50px;
            height: 50px;
            border-radius: 10px;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
            border: black solid 2.5px;
            box-shadow: 5px 5px 0px #000000;


            &:hover {
                background-color: #FFE849 !important;
            }
        }

        input[type="checkbox"]::before {
            content: "";
            width: 30px;
            height: 30px;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            transform-origin: center;
            transition: 120ms transform ease-in-out;
            box-shadow: inset 5px 5px var(--form-control-color);
            /* Windows High Contrast Mode */
            background-color: black;
        }

        input[type="checkbox"]:checked::before {
            transform: scale(1);
        }

        label[for="checkbox"] {
            padding-left: 5px;
            padding-right: 10px;
            font-weight: bold;
            font-size: 20px;
            border-radius: 10px;
            font-family: 'Interstate';
            color: black;
            margin: auto;
            background-color: white;
            border: black solid 2.5px;
            box-shadow: 5px 5px 0px #000000;
            z-index: 1;
            border-left: none;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            transform: translateX(-1px);
        }
    }



    .buttons {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        z-index: 1;

        .button {
            font-size: 25px;
            font-weight: bold;
            font-family: "Interstate";
            padding: 5px;
            line-height: 1;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-color: #FFE849;
            border-radius: 10px;
            color: black;
            width: 250px;
            border: black solid 3px;
            box-shadow: 5px 5px 0px #000000;
            text-align: center;
            transition: 0.25s cubic-bezier(0.34, 1.56, 0.64, 1) transform, 0.25s cubic-bezier(0.34, 1.56, 0.64, 1) box-shadow;

            &:hover {
                /* Scale down slightly when pressed */
                transform: translate(2.5px, 2.5px);
                box-shadow: 2.5px 2.5px 0px #000000;
            }

            &:active {
                /* Scale down slightly when pressed */
                transform: translate(5px, 5px);
                box-shadow: 0px 0px 0px #000000;
            }

            &:disabled {
                background-color: #D3D3D3; /* Light grey background to show disabled state */
                color: #A9A9A9; /* Grey text color to match the disabled tone */
                border: #A9A9A9 solid 3px; /* Grey border to soften the button */
                box-shadow: none; /* Remove shadow to make it look inactive */
                cursor: not-allowed; /* Change cursor to indicate it is not clickable */
                transform: none; /* Disable transform effects */
                /*opacity: 0.6;*/ /* Add slight transparency for visual cue */
            }

            &.alt {
                background-color: white;
            }
        }
    }
}
