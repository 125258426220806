/*@font-face {
    font-family: "Logkey";
    src: url('./fonts/LogkeyBlockFree.ttf') format('truetype');
}
*/
@font-face {
    font-family: "Interstate";
    src: url('./fonts/Interstate-Regular.otf') format('opentype');

   
}

@font-face {
    font-family: "Logkey";
    src: url('./fonts/Logkey.woff2') format('woff2'), /* modern browsers */
    url('./fonts/Logkey.woff') format('woff'); /* fallback for older browsers */
}
