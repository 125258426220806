div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.logoSection {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    touch-action: none;
    z-index: 1;
    pointer-events: none;


    .logo {
        height: 35vh;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: ease-in-out 0.5s;

        &.topLeft {
            transform: scale(0.4);
            top: -80vh;
            left: -80vw;
            right: 0;
            bottom: 0;
        }
    }
}

.gameContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    /*background-image: url('../images/Host/BG.png');*/
    background-color: #FA6C9F;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index: 0;

    .loadingContainer {
        position: absolute;
        left: 0;
        right: 0;
        /*top: 0;*/
        /* bottom: 0; */
        margin: auto;
        max-width: 700px !important;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        z-index: 1;
    }

    .roomCode {
        position: absolute;
        right: 3vh;
        top: 1vh;
        margin: auto;
        display: flex;
        flex-direction: row;
        z-index: 1;

        .textBox {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            height: fit-content;

            .text {
                text-align: end;
                font-size: 3.5vh;
                font-family: "Interstate";
                font-weight: bold;
                color: white;
                text-shadow: 0.5vh 0.5vh 0px #000000, 0.25vh 0.25vh 0px #000000;
                paint-order: stroke fill;
                -webkit-text-stroke: 1vh black;

                &:nth-child(1) {
                    transform: translateY(0.75vh);
                }

                &:nth-child(2) {
                    transform: translateY(-0.75vh);
                }

                &.code {
                }
            }

            .iconsBox {
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
                display: flex;
                flex-direction: row;
                width: fit-content;
                height: fit-content;
                z-index: 1000;

                .muteToggle {
                    width: fit-content;
                    height: fit-content;
                    position: relative;
                    margin: auto;
                    cursor: pointer;
                    padding-left: 1vh;

                    .muteIcon {
                        width: auto;
                        height: 5vh;
                        border-radius: 1vh;
                        box-shadow: 0.75vh 0.75vh 0px #000000;
                    }
                }
            }
        }

        .qrCodeBox {
            background-color: white;
            width: fit-content;
            height: fit-content;
            margin: auto;
            padding: 1vh;
            display: flex;
            border-radius: 1vh;
            margin-left: 1vh;
            margin-top: 1vh;
            border: black solid 0.5vh;
            box-shadow: 0.75vh 0.75vh 0px #000000;

            .qrCode {
                height: 14vh !important;
                width: 14vh !important;
                margin: auto;
            }
        }
    }

    .timerSection {
        position: absolute;
        width: 25vh;
        height: 12.5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 0.5vh #00B497;
        background-color: #000000;
        border-radius: 4vh;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 2vh;
        padding: 1vh;
        transition: 1s transform;
        transform: translateY(150%);
        z-index: 11;

        &.show {
            transform: translateY(0%);
        }

        .timerText {
            text-align: center;
            font-size: 9vh;
            font-family: "Resistenza";
            color: white;
            width: fit-content;
            height: fit-content;
            line-height: 1;
            position: absolute;
            left: 0;
            right: 40%;
            bottom: 0;
            margin: auto;
            top: -5%;
        }

        .clock {
            height: 17vh;
            position: absolute;
            left: 0;
            right: 0;
            transform: translate(18%, -8%);
            margin: auto;
        }
    }

    .buttonsGroup {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: scale(0);

        &.show {
            transform: scale(1);
        }
    }


    .mainButton {
        position: relative;
        font-size: 5vh;
        font-weight: bold;
        font-family: "Interstate";
        padding: 1vh;
        margin: 1vh;
        line-height: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #FFE849;
        border-radius: 2vh;
        color: black;
        width: 50vh;
        border: black solid 0.5vh;
        box-shadow: 1vh 1vh 0px #000000;
        transition: 0.25s cubic-bezier(0.34, 1.56, 0.64, 1) transform, 0.25s cubic-bezier(0.34, 1.56, 0.64, 1) box-shadow !important;

        &:hover {
            /* Scale down slightly when pressed */
            transform: translate(0.5vh, 0.5vh) !important;
            box-shadow: 0.5vh 0.5vh 0px #000000;
        }

        &:active {
            /* Scale down slightly when pressed */
            transform: translate(1vh, 1vh) !important;
            box-shadow: 0vh 0vh 0px #000000;
        }

        &:disabled {
            background-color: #D3D3D3; /* Light grey background to show disabled state */
            color: #A9A9A9; /* Grey text color to match the disabled tone */
            border: #A9A9A9 solid 0.5vh; /* Grey border to soften the button */
            box-shadow: none; /* Remove shadow to make it look inactive */
            cursor: not-allowed; /* Change cursor to indicate it is not clickable */
            transform: none; /* Disable transform effects */
            /*opacity: 0.6;*/ /* Add slight transparency for visual cue */
        }

        &.alt {
            background-color: white;
        }
    }

    .startButtonSection {
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 60vh;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 11;

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 1vh;

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: white;
                margin: 0;
                font: inherit;
                width: 7.5vh;
                height: 7.5vh;
                border-radius: 1.75vh;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;
                border: black solid 0.5vh;
                box-shadow: 0.5vh 0.5vh 0px #000000;


                &:hover {
                    background-color: #FFE849 !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: 5.5vh;
                height: 5.5vh;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: black;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                padding-left: 1vh;
                padding-right: 2vh;
                font-weight: bold;
                font-size: 3vh;
                border-radius: 1.75vh;
                font-family: 'Interstate';
                color: black;
                margin: auto;
                background-color: white;
                border: black solid 0.5vh;
                box-shadow: 0.5vh 0.5vh 0px #000000;
                z-index: 1;
                border-left: none;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                transform: translateX(-0.1vh);
            }
        }
    }

    .linesSection {
        position: absolute;
        width: 100%;
        height: 100%;
        touch-action: none;
        display: flex;
        z-index: -1;

        &.show {
            .line {
                &.top {
                    top: 2.5vh;
                }

                &.bottom {
                    bottom: 2.5vh;
                }
            }
        }

        .line {
            position: absolute;
            margin: auto;
            width: 100%;
            height: auto;
            transition: 1s ease-in-out;

            &.top {
                top: -20vh;
            }

            &.bottom {
                bottom: -20vh;
            }
        }
    }

    .teamSection {
        position: absolute;
        width: 35%;
        height: 75%;
        margin: auto;
        bottom: 0;
        display: flex;
        align-items: start;

        .playerColumn {
            position: relative;
            width: 40%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: start;
            transition: all 2s;

            .teamTitle {
                color: white;
                font-family: "Logkey";
                font-size: 10vh;
                text-align: center;
                line-height: 0.65;
                width: fit-content;
                padding-left: 2vh;
                padding-right: 2vh;
                padding-top: 0.75vh;
                padding-bottom: 0.25vh;
                border-radius: 0.5vh;
                margin-left: auto;
                margin-right: auto;
                text-wrap: nowrap;

                &.teamA {
                    background-color: #f96fa0;
                    border: solid 0.25vh #E35C8D;
                }

                &.teamB {
                    background-color: black;
                }
            }
        }

        .scoreColumn {
            width: 40%;
            height: 100%;
            display: flex;
            flex-direction: column-reverse;
            gap: 0.5vh;
            justify-content: start;
            align-items: center;

            .flag {
                width: 12vh;
                height: auto;
            }

            .scoreBox {
                width: 12vh;
                height: 2.75vh;
                position: relative;
                animation: 0.25s boxpulse linear;


                .pointGained {
                    position: absolute;
                    height: 6vh;
                    width: 6vh;
                    text-align: center;
                    font-size: 6vh;
                    font-family: "Logkey";
                    line-height: 0.65;
                    font-weight: bold;
                    opacity: 0;
                    background-color: white;
                    border-radius: 50%;
                    animation: fade 2s linear;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-top: 0.5vh;

                    &.teamA {
                        color: #f171a0;
                        left: -7vh;
                    }

                    &.teamB {
                        color: black;
                        right: -7vh;
                    }

                    @keyframes fade {
                        0% {
                            opacity: 0;
                        }

                        30% {
                            opacity: 1;
                        }

                        60% {
                            opacity: 1;
                        }

                        100% {
                            opacity: 0;
                        }
                    }
                }

                .innerBox {
                    background-color: white;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0.5;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.highlight {
                        opacity: 1;

                        .scoreNum {
                            opacity: 1;
                        }
                    }

                    &.ruleZone {
                        &.highlight {
                            background-color: #ffe74b;
                        }
                    }

                    .scoreNum {
                        text-align: center;
                        font-size: 2.5vh;
                        line-height: 1;
                        font-family: "Interstate";
                        font-weight: bold;
                        opacity: 0;

                        &.black {
                            color: black;
                        }

                        &.pink {
                            color: #f171a0;
                        }
                    }
                }


                &.dangerZone {
                    border: solid 0.5vh white;

                    .innerBox {
                        &.highlight {
                            --spacing: 20%;
                            --color-1: #fff;
                            --color-2: lightgrey;

                            &.pink {
                                --color-2: #ffd0e1;
                            }

                            background: repeating-linear-gradient(60deg, var(--color-1) 0%, var(--color-1) var(--spacing), var(--color-2) calc(var(--spacing) + 2px), var(--color-2) calc(var(--spacing)*2));
                            animation: animatedGradient 2s linear infinite;
                            background-size: 50% 100%; 

                            @keyframes animatedGradient {
                                0% {
                                    background-position: 0 0;
                                }

                                100% {
                                    background-position: 100% 0;
                                }
                            }
                        }
                    }

                    &.pulse {
                        .dangerZoneMark {
                            animation: boxpulse 2s infinite ease-in-out;
                        }
                    }
                }

                @keyframes boxpulse {
                    0% {
                        transform: scale(1);
                    }

                    50% {
                        transform: scale(1.2);
                    }

                    100% {
                        transform: scale(1);
                    }
                }

                .newRuleMark {
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: fit-content;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    .arrow {
                        width: 0;
                        height: 0;
                        border-top: 1vh solid transparent;
                        border-bottom: 1vh solid transparent;
                        border-right: 2vh solid white;
                    }

                    .ruleText {
                        font-family: "Logkey";
                        color: white;
                        font-size: 4vh;
                        text-align: center;
                        width: 75%;
                        line-height: 0.65;
                        margin-top: 0.75vh;
                    }
                }

                .dangerZoneMark {
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .warning {
                        width: 5vh;
                        height: 5vh;
                    }

                    .zoneText {
                        font-family: "Logkey";
                        color: white;
                        font-size: 5vh;
                        text-align: center;
                        width: 100%;
                        line-height: 0.65;
                        margin-top: 0.75vh;
                    }
                }
            }
        }

        &.left {
            left: 0;
            flex-direction: row;
            justify-content: start;

            .playerColumn {
                align-items: start;
                margin-left: 5vh;
            }

            .scoreBox {
                .newRuleMark {
                    right: -90%;
                }

                .dangerZoneMark {
                    right: -120%;
                }
            }
        }

        &.right {
            right: 0;
            flex-direction: row-reverse;
            justify-content: end;

            .playerColumn {
                align-items: end;
                margin-right: 5vh;
            }

            .scoreBox {
                .newRuleMark {
                    flex-direction: row-reverse;
                    left: -90%;

                    .arrow {
                        border-left: 2vh solid white;
                        border-right: unset;
                    }
                }

                .dangerZoneMark {
                    left: -120%;
                }
            }
        }
    }



    .genericTitle {
        font-size: 12vh;
        font-family: "Logkey";
        color: white;
        -webkit-text-stroke: 1vh black;
        text-shadow: 0.25vh 0.25vh 0 #000000, 0.5vh 0.5vh 0 #000, 0.75vh 0.75vh 0 #000000, 1.25vh 1.25vh 0 #000;
        paint-order: stroke fill;
        text-align: center;
        line-height: 0.65;
        text-transform: uppercase;
        transform: scale(0);

        &.bigger {
            font-size: 35vh;
        }

        &.medium {
            font-size: 23.5vh;
        }

        &.show {
            transform: scale(1);
        }
    }

    .subText {
        color: black;
        text-align: center;
        font-size: 4vh;
        font-family: "Interstate";
        font-weight: bold;
        transform: scale(0);
        transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
        text-wrap: nowrap;

        &.show {
            transform: scale(1);
            transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
        }

        &.smaller {
            font-size: 3vh;
        }
    }

    .timer {
        width: 17.5vh;
        height: 17.5vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 3.75vh;
        transform: scale(0);
        transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;

        &.show {
            transform: scale(1);
            transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
        }

        &.side {
            top: 25vh;
            right: 5vh;
        }

        .watchButton {
            width: 25%;
            position: absolute;
            top: -2.5vh;
        }

        .faceSection {
            width: 100%;
            height: 100%;
            position: relative;

            .watchFace {
                width: 100%;
                height: auto;
                position: absolute;
            }

            .faceText {
                font-family: 'Logkey';
                font-size: 14vh;
                color: black;
                text-align: center;
                z-index: 1;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 1.25vh;
                bottom: 0;
                line-height: 0.65;
                height: fit-content;
            }
        }

        &.smaller {
        }
    }

    .teamMethodSection {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        /*transition: 1s ease-in-out;*/
        touch-action: none;
        pointer-events: none;

        @for $i from 1 through 10 {
            .animate:nth-child(#{$i}n) {
                transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
                transition-delay: #{$i * 0.25}s;
                transform: scale(0);
            }
        }

        &.show {
            touch-action: unset;
            pointer-events: unset;

            @for $i from 1 through 10 {
                .animate:nth-child(#{$i}n) {
                    transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
                    transition-delay: #{$i * 0.25}s;
                    transform: scale(1);
                }
            }
        }
    }

    .chooseTeamSection {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        touch-action: none;
        pointer-events: none;

        @for $i from 1 through 10 {
            .animate:nth-child(#{$i}n) {
                transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
                transform: scale(0);
            }
        }

        &.show {
            touch-action: unset;
            pointer-events: unset;

            @for $i from 1 through 10 {
                .animate:nth-child(#{$i}n) {
                    transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
                    transform: scale(1);
                }
            }
        }

        .emptyPlayerPool {
            width: 100%;
            height: fit-content;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 2.5%;
            margin: auto;
            position: absolute;
            bottom: -7.5vh;
            top: 42vh;
            left: 0;
            right: 0;
            opacity: 1;

            .emptyPlayer {
                font-family: "Interstate";
                color: black;
                font-weight: bold;
                background-color: white;
                border-radius: 0.5vh;
                font-size: 2vh;
                border: solid black 0.5vh;
                box-shadow: 0.75vh 0.75vh 0px #000000;
                text-align: center;
                padding: 0.5vh;
                width: 23%;
                margin-top: 2vh;
                height: 5vh;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: opacity 0.5s linear;

                &.small {
                    font-size: 1.75vh;
                }

                &.hide {
                    opacity: 0.5;
                }
            }
        }

        .teamTitleGroup {
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 1s ease-in-out;
            width: fit-content;
            height: fit-content;
            pointer-events: none;
            touch-action: none;
            transform: scale(0);
            transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;

            &.show {
                pointer-events: unset;
                touch-action: unset;
                transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
                transform: scale(1);
            }
        }

        .teamSection {
            position: absolute;
            width: fit-content;
            height: fit-content;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            gap: 1vh;
            margin: auto;
            justify-content: start;
            align-items: center;
            height: 30vh;
            transform: scale(0);


            .title {
                color: white;
                font-family: "Logkey";
                font-size: 10vh;
                text-align: center;
                line-height: 0.65;
                width: fit-content;
                padding-left: 2vh;
                padding-right: 2vh;
                padding-top: 0.75vh;
                padding-bottom: 0.25vh;
                border-radius: 0.5vh;
            }

            .teamBox {
                display: flex;
                width: 40vh;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                gap: 5%;

                .teamMember {
                    font-family: "Interstate";
                    color: black;
                    font-weight: bold;
                    background-color: white;
                    border-radius: 0.5vh;
                    font-size: 2vh;
                    border: solid black 0.5vh;
                    box-shadow: 0.75vh 0.75vh 0px #000000;
                    text-align: center;
                    padding: 0.5vh;
                    width: 45%;
                    margin-top: 2vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 5vh;
                    animation: popup 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);

                    &.small {
                        font-size: 1.75vh;
                    }

                    @keyframes popup {
                        0% {
                            transform: scale(0);
                        }


                        100% {
                            transform: scale(1);
                        }
                    }
                }
            }

            &.teamA {
                left: 12.5vh;

                .title {
                    background-color: #f96fa0;
                    border: solid 0.25vh #E35C8D;
                }
            }

            &.teamB {
                right: 12.5vh;

                .title {
                    background-color: black;
                }
            }
        }
    }

    .titleGroup {
        position: absolute;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translateX(0);
        transition: 0.4s linear transform;
        transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
        transform: scale(0);

        &.show {
            transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
            transform: scale(1);
        }
    }

    .describerSection {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        touch-action: none;
        pointer-events: none;

        @for $i from 1 through 10 {
            .animate:nth-child(#{$i}n) {
                transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
                transition-delay: #{$i * 0.25}s;
                transform: scale(0);
            }
        }

        &.show {
            touch-action: unset;
            pointer-events: unset;

            @for $i from 1 through 10 {
                .animate:nth-child(#{$i}n) {
                    transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
                    transition-delay: #{$i * 0.25}s;
                    transform: scale(1);
                }
            }
        }

        .trophy {
            position: absolute;
            top: 10vh;
            width: 20vh;
            height: auto;
            left: 0;
            right: 0;
            margin: auto;
            transform: scale(0);
        }

        .playerBox {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: fit-content;
            height: fit-content;
            top: 2.5vh;
            transform: scale(0);


            .potato {
                position: relative;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                height: 17vh;
                width: auto;
                transform: translateY(3vh);
            }

            .name {
                font-family: "Interstate";
                color: black;
                font-weight: bold;
                background-color: white;
                border-radius: 0.5vh;
                font-size: 2.25vh;
                border: solid black 0.5vh;
                box-shadow: 0.75vh 0.75vh 0px #000000;
                text-align: center;
                padding: 0.5vh;
                width: fit-content;
                z-index: 1;
            }
        }

        .votingPlayerSection {
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10vh;
            width: fit-content;
            height: fit-content;
            bottom: 7.5vh;
            transition: transform linear 0.4s;
            transform: scale(0);
            transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
            transform: scale(0);

            &.show {
                transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
                transform: scale(1);
            }

            &.hide {
                transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
                transform: scale(0);
            }

            .votingPlayer {
                display: flex;
                width: fit-content;
                height: fit-content;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                transform: scale(1.35);

                &.left {
                    .name {
                        transform: translate(-3.5vh, 2vh);
                        padding-left: 1.5vh;

                        &.highlight {
                            transform: translate(-3.5vh, -1vh);
                        }
                    }
                }

                &.right {
                    .name {
                        transform: translate(3.5vh, 2vh);
                        padding-right: 1.5vh;

                        &.highlight {
                            transform: translate(3.5vh, -1vh);
                        }
                    }
                }

                .potato {
                    position: relative;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 14vh;
                    width: auto;
                    z-index: 1;
                }

                .name {
                    font-family: "Interstate";
                    color: black;
                    font-weight: bold;
                    background-color: white;
                    border-radius: 0.35vh;
                    font-size: 2vh;
                    border: solid black 0.35vh;
                    box-shadow: 0.5vh 0.5vh 0px #000000;
                    text-align: center;
                    padding: 0.35vh;
                    width: fit-content;
                    z-index: 1;
                    height: fit-content;
                    z-index: 0;
                    transition: 0.5s ease-in-out transform;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-left: 1vh;
                    padding-right: 1vh;

                    &.highlight {
                        background-color: #f2e626;
                    }
                }
            }
        }

        .voteCorrectCardsSection {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 90%;
            justify-content: center;
            height: fit-content;
            align-items: center;
            margin-top: 7vh;
            gap: 4vh;
            transform: scale(0);

            .cardGroup {
                display: flex;
                flex-direction: column;
                width: 25vh;
                height: 22.5vh;

                &.smaller {
                    width: 20vh;
                    height: 16vh;

                    .card {
                        font-size: 2.5vh;
                        height: 10vh;
                        width: 20vh;
                        height: 10vh;

                        &.two {
                            transform: translate(1.5vh, 3vh);
                        }
                    }
                }

                .card {
                    width: 25vh;
                    height: 15vh;
                    position: absolute;
                    background-color: white;
                    font-family: "Interstate";
                    font-weight: bold;
                    font-size: 3.5vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 1vh;
                    border: solid black 0.5vh;
                    border-radius: 2vh;
                    text-align: center;
                    flex-wrap: wrap;
                    line-height: 1;
                    box-shadow: 0.5vh 0.5vh 1vh rgba(0,0,0,0.5);

                    &.one {
                        transform: translate(-1.5vh, -5vh);
                    }

                    &.two {
                        transform: translate(1.5vh, 5vh);
                    }
                }
            }
        }

        .ruleCardsSection {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 17.5vh;
            transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
            transform: scale(0);


            &.show {
                transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s;
                transform: scale(1);
            }

            &.grow {
                z-index: 1;
                transform: scale(2.5) translateY(10vh) !important;
            }

            .card {
                position: absolute;
                width: 15vh;
                height: 22.55vh;
                position: absolute;
                border: solid black 0.5vh;
                border-radius: 1vh;
                text-align: center;
                flex-wrap: wrap;
                box-shadow: 0.5vh 0.5vh 1vh rgba(0,0,0,0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.4s transform cubic-bezier(0.34, 1.56, 0.64, 1);
                transform: scale(1);
                flex-direction: column;
                background-color: white;

                &.back {
                    background-color: #F7EB2E;
                }

                &.hide {
                    transform: scale(0) !important;
                    transition: transform cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
                }

                .backText {
                    font-size: 7.5vh;
                    font-family: "Logkey";
                    color: white;
                    -webkit-text-stroke: 0.5vh black;
                    text-shadow: 0.5vh 0.5vh 0 #000000, 0.75vh 0.75vh 0 #000000;
                    paint-order: stroke fill;
                    text-align: center;
                    line-height: 0.65;
                    text-transform: uppercase;
                }

                .ruleText {
                    font-family: "Interstate";
                    font-size: 1.5vh;
                    color: black;
                    text-align: center;
                    line-height: 1;
                    padding: 0.5vh;
                    width: 100%;
                    height: fit-content;
                }

                .blockText {
                    font-family: "Logkey";
                    font-size: 4vh;
                    background-color: black;
                    width: 90%;
                    height: fit-content;
                    padding-top: 1.5vh;
                    padding-bottom: 1.5vh;
                    color: white;
                    text-align: center;
                    line-height: 0.65;
                    padding-top: 1.75vh;
                    text-transform: uppercase;
                    padding-left: 0.5vh;
                    padding-right: 0.5vh;

                    &.medium {
                        font-size: 3vh;
                    }

                    &.smaller {
                        font-size: 2vh;
                    }
                }

                &.one {
                    transform: translate(-7.5vh, 4vh) rotate(-15deg) scale(1);
                }

                &.two {
                    transform: translate(5vh, 1vh) rotate(17.5deg) scale(1);
                }
            }
        }
    }

    .roundView {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        opacity: 0;
        transition: 1s ease-in-out;
        touch-action: none;
        pointer-events: none;

        &.show {
            opacity: 1;
            touch-action: unset;
            pointer-events: unset;
        }

        .roundBG {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .centerBox {
            position: absolute;
            width: 117.5vh;
            height: 70vh;
            margin: auto;
            bottom: 5vh;
            background: white;
            border-radius: 1vh;
            border: solid black 1vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transition: 0.25s all linear;
            z-index: 2;
            touch-action: none;
            pointer-events: none;

            &.shrink {
                width: 18vh;
                height: 22.5vh;
                bottom: 75vh;

                .innerBorder {
                    opacity: 0;
                }

                .title {
                    font-size: 5vh;
                    top: -14vh;

                    &.smaller {
                        font-size: 20vh;
                        bottom: 0vh;
                    }
                }
            }

            .innerBorder {
                position: absolute;
                width: 107.5vh;
                height: 60vh;
                border: solid black 0.5vh;
                border-radius: 1vh;
                margin: auto;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }

            .title {
                position: absolute;
                font-family: "Logkey";
                font-size: 30vh;
                text-align: center;
                line-height: 0.7;
                animation: scale-easeOutElastic 0.75s reverse;
                margin: auto;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: fit-content;
                height: fit-content;
                transition: 0.25s linear;

                &.smaller {
                    font-size: 12.5vh;
                    bottom: 12.5vh;
                    top: unset;
                }
            }

            @keyframes scale-easeOutElastic {
                0% {
                    transform: scale(1);
                }

                4% {
                    transform: scale(0.98);
                }

                8% {
                    transform: scale(0.99);
                }

                18% {
                    transform: scale(0.94);
                }

                26% {
                    transform: scale(0.98);
                }

                46% {
                    transform: scale(0.75);
                }

                64% {
                    transform: scale(0.98);
                }

                76% {
                    transform: scale(0.44);
                }

                88% {
                    transform: scale(0.11);
                }

                100% {
                    transform: scale(0);
                }
            }
        }


        .bigWatch {
            width: 50vh;
            height: 50vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 22.5vh;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            transition: opacity 1s ease-in-out;

            &.show {
                opacity: 1;
            }

            .watchButton {
                width: 21%;
                position: absolute;
                top: -13%;
            }

            .faceSection {
                width: 100%;
                height: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .watchFace {
                    width: 100%;
                    height: auto;
                    position: absolute;
                }

                .faceText {
                    font-family: 'Logkey';
                    font-size: 30vh;
                    color: black;
                    text-align: center;
                    z-index: 1;
                    position: absolute;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: 2vh;
                    bottom: 0;
                    line-height: 0.6;
                    height: fit-content;
                    animation: pulse 0.25s linear;
                }

                @keyframes pulse {
                    0% {
                        transform: scale(1);
                    }

                    50% {
                        transform: scale(1.1);
                    }

                    100% {
                        transform: scale(1);
                    }
                }

                .capsuleContainer {
                    position: absolute;
                    width: 82.5%;
                    height: 82.5%;
                    display: flex;
                    justify-content: center;
                    align-items: center;


                    .capsule {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: start;

                        .innerCapsule {
                            background-color: #e2e2e2;
                            width: 1vh;
                            height: 3.25vh;
                            border-radius: 2vh;

                            &.fill {
                                background-color: #fb6aa2;
                            }
                        }
                    }
                }
            }

            &.smaller {
            }
        }

        .ruleList {
            position: absolute;
            width: 70vh;
            height: fit-content;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            margin: auto;
            bottom: 5vh;
            gap: 0.5vh;
            justify-content: center;
            align-items: center;

            .rule {
                width: 100%;
                height: fit-content;
                background-color: white;
                padding: 1vh;
                padding-left: 2vh;
                padding-right: 2vh;
                font-family: "Interstate";
                font-weight: bold;
                font-size: 2.5vh;
                border: solid black 0.6vh;
                border-radius: 1.5vh;
                text-align: center;
                flex-wrap: wrap;
            }
        }
    }

    .playAgainButtonSection {
        width: 55%;
        height: 20%;
        margin: auto;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 9;

        .button {
            position: relative;
            font-size: 4.5vh;
            /*font-family: "Resistenza";*/
            padding: 1vh;
            margin: 1vh;
            line-height: 1;
            /*            background-image: url('../images/Host/ButtonImg.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-color: unset;*/
            background-color: transparent;
            color: black;
            border: none;
            text-transform: uppercase;
            z-index: 1;

            .buttonImg {
                position: absolute;
                z-index: -1;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}


@keyframes popup {
0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes shrink {
0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}